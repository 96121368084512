import React from 'react';

export const GlobalStateContext = React.createContext({});
export const GlobalDispatchContext = React.createContext({});

interface IState {
    affirmation:string,
}

const initialState:IState = {
    affirmation: ''
}

const reducer = (state: any, action: any) => {
    switch (action.type) {
        case "SET_AFFIRMATION":
            return {
                ...state,
                affirmation: action.affirmation
            }
        default:
            throw new Error(`Action: ${action.type} not found`);
    }
}

const GlobalContextProvider = ({ children }: any) => {
    const [state, dispatch] = React.useReducer(reducer, initialState);
    return <GlobalStateContext.Provider value={state}>
        <GlobalDispatchContext.Provider value={dispatch}>
            {children}
        </GlobalDispatchContext.Provider>
    </GlobalStateContext.Provider>
}

export default GlobalContextProvider;